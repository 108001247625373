/*!

=========================================================
* Material Kit PRO React - v1.9.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* Lato */

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-black-webfont.eot"),
    url("../fonts/Lato/lato-black-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-black-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-black-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-black-webfont.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-blackitalic-webfont.eot"),
    url("../fonts/Lato/lato-blackitalic-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-blackitalic-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-blackitalic-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-blackitalic-webfont.svg") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-bold-webfont.eot"),
    url("../fonts/Lato/lato-bold-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-bold-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-bold-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-bold-webfont.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-bolditalic-webfont.eot"),
    url("../fonts/Lato/lato-bolditalic-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-bolditalic-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-bolditalic-webfont.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-medium-webfont.eot"),
    url("../fonts/Lato/lato-medium-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-medium-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-medium-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-medium-webfont.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-mediumitalic-webfont.eot"),
    url("../fonts/Lato/lato-mediumitalic-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-mediumitalic-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-mediumitalic-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-mediumitalic-webfont.svg") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-regular-webfont.eot"),
    url("../fonts/Lato/lato-regular-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-regular-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-regular-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-regular-webfont.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-italic-webfont.eot"),
    url("../fonts/Lato/lato-italic-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-italic-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-italic-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-italic-webfont.svg") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-light-webfont.eot"),
    url("../fonts/Lato/lato-light-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-light-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-light-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-light-webfont.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/lato-lightitalic-webfont.eot"),
    url("../fonts/Lato/lato-lightitalic-webfont.woff") format("woff"),
    url("../fonts/Lato/lato-lightitalic-webfont.woff2") format("woff2"),
    url("../fonts/Lato/lato-lightitalic-webfont.ttf") format("truetype"),
    url("../fonts/Lato/lato-lightitalic-webfont.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}

// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";
@import "core/keyframes";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";
